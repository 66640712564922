<template>
  <v-row justify="center">
    <v-dialog
      :value="true"
      persistent
      max-width="900px"
    >
      <v-card
        elevation="2"
      >
        <edit-selected-planned-job
          v-if="editMode"
          @viewPlannedJob="editMode = false"
          :plannedJobId="plannedJobId"
        />
        <view-selected-planned-job
          v-else
          @editPlannedJob="editMode = true"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ViewSelectedPlannedJob from '@/components/Jobs/SelectedPlannedJob/View.vue';
import EditSelectedPlannedJob from '@/components/Jobs/SelectedPlannedJob/Edit.vue';

export default {
  name: 'SelectedPlannedJob',
  components: {
    ViewSelectedPlannedJob,
    EditSelectedPlannedJob,
  },
  computed: {
    plannedJobId() {
      return this.$store.state.selectedPlannedJob;
    },
  },
  data() {
    return {
      editMode: false,
    };
  },
};
</script>
