<template>
  <v-dialog
    v-model="showDialog"
    persistent
    max-width="900px"
  >
    <v-card class="pa-3">
      <div v-if="plannedJob">
        <v-row>
          <v-col
            class="pt-3 pb-0"
            cols="12"
          >
            <v-card-title>
              Update job number: {{ plannedJob.jobNumber }}
            </v-card-title>
            <v-card-text>
              You can change the date, time, vehicle and workers here.
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="pt-0 pb-0"
            cols="12"
            sm="4"
          >
            <seleted-job-date-picker
              v-if="plannedJob"
              @datePicked="onDatePicked" />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-autocomplete
              v-model="vehicle"
              :items="$store.state.vehicles"
              item-text="reg"
              item-value="id"
              label="Vehicle"
            ></v-autocomplete>
            <label>Site</label>
            <v-text-field v-model="plannedJob.site" />
            <v-autocomplete
              v-model="plannedJob.chargehandId"
              :items="$store.state.workers"
              item-text="name"
              item-value="id"
              label="Chargehand"
            ></v-autocomplete>
            <v-autocomplete
              v-model="plannedJob.worker2Id"
              :items="$store.state.workers"
              item-text="name"
              item-value="id"
              label="Worker 2"
            ></v-autocomplete>
            <v-autocomplete
              v-model="plannedJob.worker3Id"
              :items="$store.state.workers"
              item-text="name"
              item-value="id"
              label="Worker 3"
            ></v-autocomplete>
            <v-autocomplete
              v-model="plannedJob.worker4Id"
              :items="$store.state.workers"
              item-text="name"
              item-value="id"
              label="Worker 4"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-checkbox
              v-model="plannedJob.onHold"
              label="On Hold?"
              color="orange darken-2"
            ></v-checkbox>
            <v-textarea
              solo
              name="input-7-4"
              v-model="plannedJob.onHoldNotes"
              label="On Hold Notes"
            ></v-textarea>
            <v-radio-group v-model="plannedJob.day">
              <div class="day-night-radio">
                <v-radio
                  label="Day AM"
                  :value=2
                  color="orange darken-2"
                ></v-radio>
                <v-icon class="day-night-radio-icon day">
                  mdi-weather-sunny
                </v-icon>
              </div>
              <div class="day-night-radio">
                <v-radio
                  label="Day PM"
                  :value=1
                  color="orange darken-2"
                ></v-radio>
                <v-icon class="day-night-radio-icon day">
                  mdi-weather-sunny
                </v-icon>
              </div>
              <div class="day-night-radio">
                <v-radio
                  label="Night"
                  :value=0
                  color="orange darken-2"
                ></v-radio>
                <v-icon class="day-night-radio-icon night">
                  mdi-moon-waning-crescent
                </v-icon>
              </div>
            </v-radio-group>
            <v-checkbox
              v-model="plannedJob.overnight"
              label="Overnight?"
              color="orange darken-2"
            ></v-checkbox>
            <v-textarea
              v-if="plannedJob.overnight"
              solo
              v-model="plannedJob.overnightLocation"
              label="Location of overnight stay"
            ></v-textarea>
            <v-text-field
              v-if="plannedJob.overnight"
              label="Accommodation cost £"
              type="number"
              min="0"
              step="0.01"
              v-model="plannedJob.overnightCost"
            ></v-text-field>
            <v-text-field
              v-if="plannedJob.overnight"
              label="Booking reference"
              v-model="plannedJob.overnightBookingRef"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="showWarning">
          <v-col>
            {{ warning }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-card-actions class="float-right">
              <v-btn
                @click="saveChanges"
                small
                color="orange darken-1"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Save
              </v-btn>
              <v-btn
                small
                color="blue-grey lighten-4"
                @click="abandonChanges"
              >
                <v-icon left>
                  mdi-close
                </v-icon>
                Abandon Changes
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import SeletedJobDatePicker from '@/components/Jobs/SelectedJob/SeletedJobDatePicker.vue';
import { eachDayOfInterval } from 'date-fns';
import axios from '../../../axios';

export default {
  name: 'EditSelectedPlannedJob',
  props: [
    'plannedJobId',
  ],
  components: {
    SeletedJobDatePicker,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    vehicle() {
      this.plannedJob.vehicle = this.vehicle;
      const vehicle = this.$store.state.vehicles.filter((v) => v.id === this.vehicle)[0];
      if (vehicle.chargehand) { this.plannedJob.chargehandId = vehicle.chargehand; }
      if (vehicle.worker2) { this.plannedJob.worker2Id = vehicle.worker2; }
      if (vehicle.worker3) { this.plannedJob.worker3Id = vehicle.worker3; }
      if (vehicle.worker4) { this.plannedJob.worker4Id = vehicle.worker4; }
      /*
      if (vehicle.unavailableDates) {
        const available = this.checkAvailable(vehicle);
        if (available === false) {
          this.warning = 'This vehicle has planned maintenance for the date selected. Are you sure this is OK?';
          this.showWarning = true;
        }
      }
      */
      this.checkWorkerAvailability();
    },
  },
  data() {
    return {
      vehicle: {},
      initialDate: null,
      plannedJob: {},
      showDialog: true,
      warning: '',
      showWarning: false,
    };
  },
  methods: {
    abandonChanges() {
      this.$emit('viewPlannedJob');
    },
    saveChanges() {
      if (this.vehicle) {
        axios.post(`/plannedJobs/update/${this.plannedJobId}.json?token=${this.token}`, this.plannedJob)
          .then(() => {
            this.$store.dispatch('refreshPlannedJobs');
            this.$store.commit('jobAdded');
            this.$emit('viewPlannedJob');
          });
      } else {
        this.warning = 'You must select a vehicle.';
        this.showWarning = true;
      }
    },
    onDatePicked(value) {
      this.plannedJob.dates = value;
    },
    loadJob() {
      axios.get(`/plannedJobs/fetch/${this.plannedJobId}.json?token=${this.token}`)
        .then((response) => {
          const dates = [];
          this.plannedJob = response.data;
          if (response.data.day === 'Day AM') {
            this.plannedJob.day = 2;
          }
          if (response.data.day === 'Day PM') {
            this.plannedJob.day = 1;
          }
          if (response.data.day === 'Night') {
            this.plannedJob.day = 0;
          }
          dates.push(this.plannedJob.date);
          dates.push(this.plannedJob.dateEnd);
          this.vehicle = this.plannedJob.vehicle;
          this.$store.commit('setInitialDate', dates);
        });
    },
    checkWorkerAvailability() {
      let available = true;
      const workersToCheck = [];
      const unavailableWorkers = [];
      workersToCheck.push(this.plannedJob.chargehandId);
      workersToCheck.push(this.plannedJob.worker2Id);
      workersToCheck.push(this.plannedJob.worker3Id);
      workersToCheck.push(this.plannedJob.worker4Id);
      for (let i = 0; i < workersToCheck.length; i += 1) {
        const worker = this.$store.state.workers.filter(
          (wrkr) => wrkr.id === workersToCheck[i],
        )[0];
        if (worker) {
          if (worker.unavailableDates) {
            for (let x = 0; x < worker.unavailableDates.length; x += 1) {
              const bDs = eachDayOfInterval({
                start: new Date(worker.unavailableDates[x].start),
                end: new Date(worker.unavailableDates[x].end),
              });
              const formattedDates = [];
              for (let z = 0; z < bDs.length; z += 1) {
                formattedDates.push(`${bDs[z].getFullYear()}${(bDs[z].getMonth() + 1)}${bDs[z].getDate()}`);
              }
              const testDate = new Date(this.plannedJob.date);
              const tD = `${testDate.getFullYear()}${(testDate.getMonth() + 1)}${testDate.getDate()}`;
              available = !formattedDates.includes(tD);
              if (available === false) {
                unavailableWorkers.push(worker.id);
                break;
              }
            }
          }
        }
      }
      if (unavailableWorkers.length > 0) {
        this.warnAboutWorkers(unavailableWorkers);
      }
    },
  },
  mounted() {
    this.loadJob();
  },
};
</script>
