<template>
  <v-container>
    <v-row>
      <v-col
        class="pt-3 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-title>
          Vehicle: {{ plannedJob.vehicleReg }}
        </v-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-subtitle class="pt-4 pb-0">
          From: {{ plannedJob.date | shortDate }}
          &nbsp;
          To: {{ plannedJob.dateEnd | shortDate }}
        </v-card-subtitle>
        <v-card-subtitle class="pt-4 pb-0">
          {{ plannedJob.day }}
        </v-card-subtitle>
        <v-card-subtitle>
          Client: {{ plannedJob.clientName }}
        </v-card-subtitle>
      </v-col>
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-subtitle>
          Job number: {{ plannedJob.jobNumber }}
        </v-card-subtitle>
        <v-card-subtitle>
          Site: {{ plannedJob.site }}
        </v-card-subtitle>
      </v-col>
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-text>
          Site address: {{ plannedJob.jobAddress }}
        </v-card-text>
      </v-col>
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-subtitle>
          Client contact: {{ plannedJob.clientContact }}
        </v-card-subtitle>
      </v-col>
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-text>
          Time to site: {{ plannedJob.timeToSite }}
        </v-card-text>
      </v-col>
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-text>
          Miles to site: {{ plannedJob.milesToSite }}
        </v-card-text>
      </v-col>
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-subtitle>
          Overnight?
          <span v-if="plannedJob.overnight">Yes</span>
          <span v-else>No</span>
        </v-card-subtitle>
        <v-card-text v-if="plannedJob.overnight">
          Overnight location: {{ plannedJob.overnightLocation }}<br />
          Booking ref: {{ plannedJob.overnightBookingRef }}<br />
          Cost: {{ plannedJob.overnightCost | currency }}
        </v-card-text>
      </v-col>
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-text>
          Description: {{ plannedJob.description }}
        </v-card-text>
      </v-col>
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-subtitle>
          Chargehand: {{ plannedJob.chargehandName }}
        </v-card-subtitle>
      </v-col>
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-subtitle v-if="plannedJob.worker2Name">
          Worker 1: {{ plannedJob.worker2Name }}
        </v-card-subtitle>
      </v-col>
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-subtitle v-if="plannedJob.worker3Name">
          Worker 2: {{ plannedJob.worker3Name }}
        </v-card-subtitle>
      </v-col>
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-subtitle v-if="plannedJob.worker4Name">
          Worker 3: {{ plannedJob.worker4Name }}
        </v-card-subtitle>
      </v-col>
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-subtitle v-if="plannedJob.onHold">
          This job is on hold
        </v-card-subtitle>
      </v-col>
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="6"
      >
        <v-card-subtitle v-if="plannedJob.onHold">
          {{ plannedJob.onHoldNotes }}
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card-actions class="float-right">
          <router-link :to="`/live-jobs/view/${plannedJob.quotationId}`">
            <v-btn
              small
              color="orange accent-2"
              >
              <v-icon left>
                mdi-card-bulleted-outline
              </v-icon>
              Job Card
            </v-btn>
          </router-link>
          <v-btn
            @click="updatePlannedJob"
            small
            color="orange darken-2"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            Update
          </v-btn>
          &nbsp;
          <v-dialog
            v-model="dialog"
            width="600"
            class="pt-3 pb-3"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="red darken-2"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
                Delete
              </v-btn>
            </template>
            <v-card class="pt-6 pb-4">
              <v-card-text>
                Are you sure you want to delete the planned work? This action cannot be undone.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  color="red darken-2"
                  @click="deletePlannedJob"
                >
                  <v-icon left>
                    mdi-delete
                  </v-icon>
                  Confirm delete
                </v-btn>
                <v-btn
                  small
                  color="blue-grey lighten-4"
                  @click="dialog = false"
                >
                  <v-icon left>
                    mdi-close
                  </v-icon>
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            small
            color="blue-grey lighten-4"
            @click="$store.commit('selectPlannedJob', false)"
          >
            <v-icon left>
              mdi-close
            </v-icon>
            Close
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'ViewSelectedPlannedJob',
  computed: {
    plannedJobId() {
      return this.$store.state.selectedPlannedJob;
    },
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      plannedJob: {
        vehicleReg: '',
      },
      dialog: false,
    };
  },
  methods: {
    updatePlannedJob() {
      this.$emit('editPlannedJob');
    },
    deletePlannedJob() {
      axios.post(`/plannedJobs/delete.json?token=${this.token}`, this.plannedJob)
        .then(() => {
          this.$store.dispatch('refreshPlannedJobs');
          this.$store.commit('selectPlannedJob', false);
          this.$store.commit('jobAdded');
        });
    },
    loadJob() {
      axios.get(`/plannedJobs/fetch/${this.plannedJobId}.json?token=${this.token}`)
        .then((response) => {
          const dates = [];
          this.plannedJob = response.data;
          dates.push(this.plannedJob.date);
          dates.push(this.plannedJob.dateEnd);
          this.vehicle = this.plannedJob.vehicle;
          this.$store.commit('setInitialDate', dates);
        });
    },
  },
  mounted() {
    this.loadJob();
  },
};
</script>
