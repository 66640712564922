<template>
  <div>
    <v-text-field
      v-model="date"
      label="Select date"
      prepend-icon="mdi-calendar"
      readonly
    >
      <template #label>
        <span class="red--text"><strong>* </strong></span>Select Date
      </template>
    </v-text-field>
    <v-date-picker
      v-model="date"
      range
      full-width
      @input="saveDate"
      header-color="orange lighten-1"
      color="orange lighten-1"
      no-title
      :show-current="false"
    ></v-date-picker>
    <p>
      <i>
        You can select a single date or a range of dates using the calendar.
        Click once to select a date, click again to select a second date, this
        will select all dates between the two clicked dates. Click a third time
        to clear the range and select a single date.
      </i>
    </p>
  </div>
</template>

<script>
export default {
  name: 'SelectedDatePicker',
  data() {
    return {
      date: null,
      showPicker: false,
    };
  },
  computed: {
    initialDate() {
      return this.$store.state.initialDate;
    },
  },
  watch: {
    initialDate: {
      handler() {
        this.setInitialDate();
      },
      immediate: true,
    },
  },
  methods: {
    saveDate() {
      this.$emit('datePicked', this.date);
    },
    setInitialDate() {
      this.date = this.initialDate;
    },
  },
  mounted() {
    this.setInitialDate();
  },
};
</script>
